<template>
    <div class="row">

        <!-- error summary starts -->
        <error-summary :errors="errors"></error-summary>
        <!-- error summary ends -->

        <div id="confirm_error" class="column small-12 medium-12 form__success" v-if="updateRequestMessage">
            <h4>Your request has been submitted</h4>
            <p>{{ updateRequestMessage }}</p>
        </div>
        <div class="column small-12 medium-12">
            <form method="get" action="" name="staff" @submit.prevent="testing" class="staff-search__form form--full-width" autocomplete="off">
                <fieldset class="staff-search__fieldset">
                    <label for="staff-search__query">Search by name, phone, email or organisational unit</label>
                        <autocomplete-component-v2
                                id="contactId"
                                :ApiUrl="'Contact\\RpcSearchContacts'"
                                :minSearchableChar="3"
                                :isAsync="true"
                                placeholder="Search by name, job title, organisation, phone or email address"
                                autocompleteComponentId="contactId"
                                :fields="['hash', 'full_name']"
                                :idField="'hash'"
                                :displayFields="['full_name']"
                                :className="'typeahead staff-search__query'"
                                :containerClassName="'staff-search'"
                                :initialValue="this.searchTerm"
                                style="width: 100%"
                                name="contactId"
                                v-model="contactId"
                                @update:searchTerm="updateSearchTerm"
                        />
                </fieldset>
            </form>
        </div>


        <div class="column small-12 medium-12" v-if="searchTerm !== ''">
            <p>You searched for "<strong>{{ searchTerm }}</strong>". The following contacts were returned:<br>
                <span  v-if="contacts.length === 0">
                    No contacts were found. Please search again, or try the contact options below.
                </span>
                <span v-else-if="itemsFloor !== itemsCeil">
                    Showing results {{ itemsFloor }} - {{ itemsCeil }}  of {{ pagination.total }}
                </span>
                <span v-else-if="itemsFloor === itemsCeil">
                    Showing results {{ itemsFloor }} of {{ pagination.total }}
                </span>
            </p>
        </div>


        <div class="column small-12 medium-12 collapse  vertical-list vertical-list--card">
            <div class="vertical-list__item" v-for="contact in contacts" :key="contact.hash">
                <div class="person">
                    <div class="person__display-name">{{ contact.full_name }}</div>
                    <div class="uq-row stacked-lg">
                        <div class="uq-column">
                            <div class="person__position" v-for="position in contact.positions" :key="position.position">
                                <div class="person__position-title">{{ position.position }}</div>
                                <div class="person__position-organisation">{{ position.org_prefix }} {{ position.org_name }}
                                </div>
                            </div>
                        </div>
                        <div class="uq-column">
                            <div v-for="numberSet in contact.phone_numbers" :key="numberSet.hash" data-phone-block>
                                <div v-if="numberSet.number" class="person__phone" >
                                    <a class="list-icon list-icon--phone" data-phone-number
                                       :href="'tel:'+numberSet.number" v-html="formatNumbers(numberSet.number, numberSet.int)"></a>
                                </div>
                                <div v-if="numberSet.int && !numberSet.number" data-int-number>{{ numberSet.int }}</div>
                            </div>
                            <div class="person__email"><a class="list-icon list-icon--email"
                                                          :href="'mailto:'+contact.email">{{ contact.email }}</a></div>
                        </div>
                        <div class="uq-column">
                            <a :href="'/contacts/edit/'+contact.hash"><small>Suggest edit</small></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="item-list" v-if="contacts.length > 0">
            <PaginationComponent
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.pageSize"
                    :total="pagination.total"
                    @update:modelValue="getContactData"
            >
            </PaginationComponent>
        </div>

    </div>
</template>
<script src="/javascript/Rpc.js"></script>
<script src="/javascript/script.js"></script>
<!--<script src="/javascript/slide-menu.js"></script>-->
<script>
    import ErrorSummary
        from "../../Shared/VueComponents/ErrorSummary";
    import AutocompleteComponentV2
        from "../../Shared/VueComponents/AutocompleteComponentV2.vue";
    import PaginationComponent
        from "../../Shared/VueComponents/PaginationComponent.vue";
    import intlTelInput from 'intl-tel-input';
    import 'intl-tel-input/build/css/intlTelInput.css';

    export default {
        name: "ContactPage",
        components: {
            ErrorSummary,
            AutocompleteComponentV2,
            PaginationComponent,
            intlTelInput,
        },
        watch: {
            contactId: function (val, oldVal) {
                if ( val !== '' && val !== oldVal && val !== this.initialContactId )
                {
                    window.location.href = `/contacts/view/${val}`;
                }

            },
        },
        data() {
            return {
                contactId:'',
                errors: new StructuredError({}),
                intlTelInput            : null,
                contacts                : [],
                pagination          : {
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                searchTerm              :''
            };
        },
        computed: {
            itemsFloor() {
                return ( this.pagination.pageSize * ( this.pagination.currentPage - 1 ) ) < 1 ? 1 : ( ( this.pagination.pageSize * ( this.pagination.currentPage - 1 ) + 1 ) );
            },
            itemsCeil() {
                return ( this.pagination.pageSize * this.pagination.currentPage ) > this.pagination.total ? this.pagination.total : ( this.pagination.pageSize * this.pagination.currentPage );
            },
        },
        props:{
            token:{
                type: String,
                required: true,
            },
            initialContactId:{
                type: String,
                required: false,
                default: ''
            },
            initialSearchTerm:{
                type: String,
                required: false,
                default: ''
            },
            updateRequestMessage:{
                type: String,
                required: false,
                default: ''
            },
            assetPrefix:{
                type: String,
                required: true,
            }
        },
        mounted () {

            this.getContactData();
        },

        methods:{
            fetchContacts: function(searchTerm, itemsPerPage, page, fields, searchField){

                let params                  = {
                    searchTerm:     searchTerm,
                    itemsPerPage:   itemsPerPage,
                    page:           page,
                    fields:         fields,
                    searchField:    searchField,
                };

                let rpc                     = new RpcRequest('Contact\\RpcSearchContacts', params);
                let that                    = this;
                rpc.send(function(res) {

                    that.contacts                   = res.result.data;
                    that.pagination.total           = res.result.total;
                    that.pagination.currentPage     = res.result.page;
                    that.pagination.pageSize        = res.result.itemsPerPage;
                    if ( ! that.searchTerm && that.contacts[0] )
                    {
                        that.searchTerm                 = that.contacts[0].full_name;
                    }

                }, function(res) {

                    that.errors                         = res.getErrors();

                });
            },
            getContactData: function()
            {
                if ( this.initialContactId )
                {
                    this.fetchContacts(this.initialContactId, this.pagination.pageSize, this.pagination.currentPage, ['*'], 'id');
                }
                else if ( this.initialSearchTerm )
                {
                    this.searchTerm     = this.initialSearchTerm;
                    this.fetchContacts(this.initialSearchTerm, this.pagination.pageSize, this.pagination.currentPage, ['*']);
                }
                window.scrollTo(0, 0);
            },
            updateSearchTerm: function(newSearchTerm)
            {
                if ( newSearchTerm !== '' && this.searchTerm !== newSearchTerm )
                {
                    window.location.href = `/contacts?searchTerm=${newSearchTerm}`;
                }
            },
            formatNumbers:  function(phoneNumber, intNumber)
            {
                phoneNumber             = phoneNumber.replace(/-/g, ' ');
                let phoneText           = phoneNumber.replace(/[^0-9]+/gi, '');
                let intText             = intNumber;
                if ( intText.length !== 5 && intText.length !== 6 )
                {
                    return phoneNumber;
                }
                if ( phoneText.length !== 11 || phoneText.substring(phoneText.length - intText.length, phoneText.length) !== intText )
                {
                    return phoneNumber;
                }

                return phoneNumber.substring(0, phoneNumber.length - intText.length - 1) + '<strong>' + phoneNumber.substring(phoneNumber.length - intText.length - 1, phoneNumber.length) + '</strong>';
            },
            resetErrors(){
                this.errors                     = new StructuredError({});
            }
        },
    }
</script>

<style lang="css">
.iti{
    display: block !important;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>


<style>
    @import '/asset/20200624/css/uqds.css';
    @import '/asset/20200624/css/uq_standard.css';
    @import '/asset/20200624/css/identity-portal.css';
    @import '/asset/20200624/css/contacts-styles.css';
    @import '/asset/20200624/css/uq-contacts-styles.css';
    @import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
</style>

<!--

node_modules/@vue/cli-service/bin/vue-cli-service.js build --target wc Pages/Shared/VueComponents/PaginationComponent.vue --dest ./public/Pages/Shared/VueComponents/PaginationComponent
node_modules/@vue/cli-service/bin/vue-cli-service.js build --target wc Pages/Shared/VueComponents/AutocompleteComponentV2.vue --dest ./public/Pages/Shared/VueComponents/AutocompleteComponentV2
node_modules/@vue/cli-service/bin/vue-cli-service.js build --target wc Pages/Contact/ContactPage/ContactPage.vue --dest ./public/Pages/Contact/ContactPage/ContactPage

-->