<template>
    <div :class="'autocomplete ' + containerClassName">
            <span class="twitter-typeahead"
                  style="position: relative; display: inline-block;">
                <input

                        @input="onChange(false)"
                        @keydown.down="onArrowDown"
                        @keydown.up="onArrowUp"
                        @keydown.enter.prevent="onEnter"
                        ref="search"
                        v-model="search"
                        type="text"
                        :placeholder="placeholder"
                        :class="className"
                        :name="autocompleteComponentId"
                        autocomplete="off"
                        @click="handleInsideClick"
                /></span>
                <button type="submit"
                        class="button"
                        v-on:click.prevent="onEnter"
                        aria-label="Search">
                  <span class="list-icon list-icon--search"></span>
                </button>

        <ul
                v-show="isOpen"
                class="autocomplete-results"
                style="margin-top: 1.5rem"
        >
            <li
                    v-if="isLoading"
                    class="loading"
            >
                Loading results...
            </li>
            <li
                    v-else
                    v-for="(result, i) in results"
                    :key="i"
                    @mousedown.prevent
                    @click="setResult(result, i)"
                    class="autocomplete-result"
                    :class="{ 'is-active': i === arrowCounter }"
            >
                {{
                    displayValue(result)
                }}
            </li>
        </ul>
    </div>
</template>

<script
    src="/javascript/Rpc.js"></script>
<script>

export default {
    name: 'AutocompleteComponentV2',
    components: {},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false,
        },
        ApiUrl: {
            type: String,
            required: false,
            default: '',
        },
        fields: {
            type: Array,
            required: false,
            default: function () {
                return ['Id', 'Name']
            },
        },
        displayFields: {
            type: Array,
            required: false,
            default: function () {
                return ['Name']
            },
        },
        idField: {
            type: String,
            default: 'Id'
        },
        placeholder: {
            type: String,
            default: 'Enter an item name to search'
        },
        className: {
            type: String,
            default: ''
        },
        containerClassName: {
            type: String,
            default: ''
        },
        leftIcon: {
            type: String,
            default: ''
        },
        autocompleteComponentId: {
            type: String,
            default: 'autocompleteComponentId'
        },
        initialValue: {
            type: [Number, String],
            default: ''
        },
        minSearchableChar: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            search: '',
            results: [],
            isOpen: false,
            arrowCounter: -1,
            isLoading: false,
        };
    },
    watch: {
        items: function (value) {
            if (this.isAsync) {
                this.results = value;
                this.isOpen = true;
                this.isLoading = false;
            } else {
                this.setInitialValue();
            }
        },
        initialValue: function () {
            this.setInitialValue();
        }
    },
    computed: {},
    mounted() {
        document.addEventListener('click', this.handleClickOutside);

        this.setInitialValue();
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        async setInitialValue() {

            if (!this.isAsync && !this.items.length > 0) {
                return;
            }
            if (this.initialValue.length > 0 || this.initialValue > 0) {
                if (this.isAsync) {
                    this.search = this.initialValue.toString();

                    return;
                }

                let that = this;
                let item = await this.items.filter(function (item) {

                    return item[this.idField] === that.initialValue;
                });
                if (item.length < 1) {
                    return;
                }

                this.search = this.displayValue(item[0]);
            }
        },
        displayValue(result) {
            let displayValue = '';
            this.displayFields.forEach((item) => {

                displayValue += " " + result[item];
            })

            return displayValue;

        },
        filterResults() {
            this.results = this.items.filter(item => this.displayValue(item).toLowerCase().indexOf(this.search.toLowerCase()) > -1);
        },
        onChange(immediate = false) {

            if (this.search === "") {
                this.$emit('update:modelValue', "");
                this.$emit('input', "");
            }

            if (this.isAsync && this.ApiUrl !== '') {
                if (immediate) {
                    this.isLoading = true;
                    this.Apicall();
                } else {
                    this.isLoading = true;

                    if (this.timeout) clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {

                        this.Apicall();

                    }, 400)
                }

            } else {
                this.filterResults();
                this.isOpen = true;
            }
        },
        setResult(result) {
            this.search = this.displayValue(result);
            this.isOpen = false;
            this.$emit('input', result[this.idField])
            this.$emit('update:modelValue', result[this.idField]);
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.arrowCounter = -1;
                this.isOpen = false;
            }
        },
        handleInsideClick(event) {

            event.preventDefault();
            event.stopPropagation();
            if (this.results.length > 0) {
                this.isOpen = true;

                return;
            }
            this.onChange(true);

        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEnter() {

            if (!this.results[this.arrowCounter]) {
                this.$emit('update:searchTerm', this.search)

                return;
            }
            this.search = this.displayValue(this.results[this.arrowCounter]);
            this.$emit('input', this.results[this.arrowCounter][this.idField])
            this.$emit('update:modelValue', this.results[this.arrowCounter][this.idField])
            this.arrowCounter = -1;
            this.isOpen = false;

        },
        // getData({ page, pageSize, sorts, filters, searchTerm, fields }) {
        //
        //   const webApiUrl = process.env.VUE_APP_WOODINA_WEB_API + this.ApiUrl;
        //
        //   return axios
        //       .get(webApiUrl, {
        //         params: {
        //           page,
        //           pageSize,
        //           sorts,
        //           filters,
        //           searchTerm,
        //           fields
        //         }
        //       })
        //       .then(response => {
        //
        //         let { data } = response;
        //
        //         return {
        //           data: data,
        //           total: parseInt(response.headers['x-app-total']),
        //           page: parseInt(response.headers['x-app-page']),
        //           pageSize: parseInt(response.headers['x-app-pagesize']),
        //           count: parseInt(response.headers['x-app-count']),
        //         };
        //       }).catch(error => {
        //
        //         return error.response;
        //       });
        // },
        async Apicall() {
            if (this.isAsync && this.ApiUrl !== '' && this.search.length >= this.minSearchableChar) {

                let params = {
                    searchTerm: this.search,
                    itemsPerPage: 500,
                    page: 1,
                    fields: this.fields
                };
                let rpc = new RpcRequest(this.ApiUrl, params);
                let that = this;
                rpc.send(function (res) {

                    that.results = res.result.data;
                    that.isOpen = true;
                    that.isLoading = false;

                }, function (res) {

                });


            }
        }
    }
}
</script>

<style scoped>
.autocomplete {
    /*display: inline-block;*/
    position: relative;
}


.suggestion-list li {
    cursor: pointer;
}

.suggestion-list li:hover {
    color: #fff;
    background-color: #ccc;
}

.autocomplete-results {
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #ddd;
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 150px;
    overflow: auto;
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 2;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    margin: 0;
}

/*.autocomplete-result li {*/
/*  margin: 0;*/
/*}*/


/*.autocomplete-result ul li {*/
/*  margin: 0;*/
/*}*/

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #bbbbbb;
    color: white;
}


</style>

<!--

node_modules/@vue/cli-service/bin/vue-cli-service.js build --target wc Pages/Shared/VueComponents/AutocompleteComponentV2.vue --dest ./public/Pages/Shared/VueComponents/AutocompleteComponentV2

-->