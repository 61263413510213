<template>
  <ul class="pager" :class="paginationClass">
    <li class="pager-item prev-page"
        :class="{ disabled: currentPage === 1 ,  hidden: currentPage === 1  }"
    >
      <a class="page-link" aria-label="First" @click="firstPage" title="Go to first page"> « first </a>
    </li>
    <li class="pager-item prev-page"
        :class="{ disabled: currentPage === 1 ,  hidden: currentPage === 1  }"
    >
      <a class="page-link" aria-label="Previous" @click="prevPage" title="Go to page previous"> « previous </a>
    </li>
    <li
      class="pager-item"
      v-for="item in range(minPage, maxPage)"
      :key="item"
      :class="{ 'pager-current': currentPage === item }"
    >
      <a class="page-link" @click="changePage(item)">{{ item }}</a>
    </li>
    <li
      class="pager-item page-pre next-page"
      :class="{ disabled: currentPage === totalPages,  hidden: currentPage === totalPages }"
    >
      <a class="page-link" aria-label="Next" @click="nextPage" title="Go to page next"> next » </a>
    </li>
    <li
      class="pager-item page-pre next-page"
      :class="{ disabled: currentPage === totalPages,  hidden: currentPage === totalPages }"
    >
      <a class="page-link" aria-label="Last" @click="lastPage" title="Go to last page"> last » </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "PaginationComponent",
  emits: ["update:modelValue"],
  props: {
    type: {
      type: String,
      default: "primary",
      validator: (value) => {
        return [
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
        ].includes(value);
      },
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  mounted () {
    this.currentPage = this.modelValue;
  },
  computed: {
    paginationClass() {
      return `pagination-${this.type}`;
    },
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.currentPage >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.currentPage;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.currentPage - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.currentPage >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.currentPage;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
      currentPage: 1
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.currentPage                  = item;
      this.$emit("input", this.currentPage);
      this.$emit("update:modelValue", this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage                = this.currentPage + 1;
        this.$emit("input", this.currentPage);
        this.$emit("update:modelValue", this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage                = this.currentPage - 1;
        this.$emit("input", this.currentPage);
        this.$emit("update:modelValue", this.currentPage);
      }
    },
    firstPage() {
      if ( this.currentPage > 1 ) {
        this.currentPage                = 1;
        this.$emit("input", this.currentPage);
        this.$emit("update:modelValue", this.currentPage);
      }
    },
    lastPage() {
      if (this.currentPage > 0 && this.currentPage < this.totalPages) {
        this.currentPage                = this.totalPages;
        this.$emit("input", this.currentPage);
        this.$emit("update:modelValue", this.currentPage);
      }
    },
  },
  // watch: {
  //   perPage() {
  //     this.$emit("update:modelValue", 1);
  //   },
  //   total() {
  //     this.$emit("update:modelValue", 1);
  //   },
  // },
};
</script>
<style>
.pager-current a{
  color: #000000;
}
</style>